import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { deleteScorecard, emailLeaderboard, getLeaderboard, getLeaderboardGross, getTournamentData, getTournamentScorecard, postScoreCard } from '../api/tournamentAPI';
import swal from 'sweetalert2';
import RightSideDetails from '../global/rightSideDetails';
import StrokePlayGross from './format/StrokePlayGross';
import StrokePlayNet from './format/StrokePlayNet';
import FourBall from './format/Fourball';
import StableFordGross from './format/StableFordGross';
import StableFordNet from './format/StableFordNet';
import Bogey from './format/Bogey';
import ModifiedStableford from './format/ModifiedStableford';
import CumulativeFourBall from './format/CumulativeFourball';
import { useHistory } from 'react-router-dom'
import Foursomes from './format/Foursomes';
import Greensomes from './format/Greensomes';
import Scramble from './format/Scramble';
import BetterballBogey from './format/BetterballBogey';
import ScrambleStrokePlayNet from './format/ScrambleStrokePlayNet';

export const PointModal =(props)=> {

    useEffect(() => {
        $("#modalPoint").modal({ backdrop: "static" });      
        $("#modalPoint").on("hidden.bs.modal",()=>{             
            props?.onDismissModal() 
        });    
    }, [props?.show]);

    return(<>
         <div className="modal fade" id="modalPoint" tabIndex="-1" role="dialog" aria-hidden="true">       
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"> Points </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                    </div>         
                    <div className="modal-body bg-grey">
                        <div className="kt-portlet">
                            <div className="kt-portlet__body">
                                {!!props.pointData && props.pointData.length > 0 && (
                                    <table id="tblPoint" className="table table-bordered">
                                        <thead className="thead-light">
                                            <tr><th>Name</th><th>Score</th><th>Point</th></tr>
                                        </thead>
                                        <tbody> 
                                            {props.pointData.map((item,i)=>{
                                                return(<tr key={i}>
                                                    <td>{item.Name}</td>
                                                    <td>{item.Score}</td>
                                                    <td>{item.Point}</td>
                                                </tr>)                                        
                                            })}
                                        </tbody>
                                    </table>                 
                                )}
                            </div>                             
                        </div>

                    </div>                             
                </div>
            </div>
        </div>
    </>)
}


const LeaderBoardPlayer = (props) => {

    const param = useParams();
    const [isInit,                  setIsInit]                      = useState(true);
    const [tournamentDetails,       setTournamentDetails]           = useState(null)
    const [tournamentRound,         setTournamentRound]             = useState([]);
    const [tournamentCategory,      setTournamentCategory]          = useState([]);
    const [selectedRound,           setSelectedRound]               = useState();
    const [selectedCategory,        setSelectedCategory]            = useState();
    const [format,                  setFormat]                      = useState();
    const [leaderBoardList,         setLeaderBoardList]             = useState([]);
    const [grossCategoryValue,      setGrossCategoryValue]          = useState();
    const [openDrawer,              setOpenDrawer]                  = useState(false);
    const [selectedPlayer,          setSelectedPlayer]              = useState(null);
    const [scorecardStatus,         setScorecardStatus]             = useState('');
    const [gameDetails,             setGameDetails]                 = useState(null);
    const [isTable,                 setIsTable]                     = useState(false);
    const [pointData,               setPointData]                   = useState([]);
    const [showPointModal,          setShowPointModal]              = useState(false);
    const [tournamentDraw,          setTournamentDraw]              = useState();
    const [playerList,              setPlayerList]                  = useState([]);

    const history = useHistory();

    useEffect(() => {
        props.title("Leaderboard", 'tournament-leaderboard');
        fetchTournamentData(param.id)
    }, []);

    useEffect(() => {
        if (!!selectedRound && !!tournamentRound) {
            const round = tournamentRound.find(r => r.ID == selectedRound)
            if (round) setFormat(round)
        }
        if (!!selectedCategory && !!selectedRound && !!tournamentRound) {
            const round = tournamentRound.find(r => r.ID == selectedRound)
            if (round) {
                // setIsInit(true)
                fetchTournamentLeaderboard(round.Round, selectedCategory)
            }
        }
    }, [selectedRound, tournamentRound, selectedCategory]);

    useEffect(() => {
        if (selectedPlayer) {
            Config.Core.OpenDrawer()
        }
    }, [selectedPlayer])

    useEffect(() => {    
        if (!isInit) fillDataTable();        
    }, [leaderBoardList]);

    function fillDataTable() {
        const round = tournamentRound.find(r => r.ID == selectedRound)
        let columns = [
            {
                field: 'Position',
                title: 'Position',
                width: 80
            },
            {
                field: 'Slot',
                title: 'Slot',
                width: 50,
            },
            { field: 'Player', title: 'Player', width: 180 },
            {
                field: 'Total', title: 'Total', width: 50,
                template: function (row) {
                    let total = '-'
                    if (row.Thru != 0) {
                        if (row.Format == "Stroke Play Net" || row.Format == "Stroke Play Gross" || (selectedCategory == grossCategoryValue)) {
                            if (row.Total > 0) {
                                total = '+' + row.Total;
                            } else if (row.Total == 0)
                                total = 'E';
                            else
                                total = row.Total;
                        }
                        else {
                            total = row.Total;
                        }
                    }
                    return total == null ? '-' : total;
                }
            },
            { field: 'Thru', title: 'Thru', width: 50 },
            {
                field: 'Round',
                title: `Round ${round.Round}`,
                width: 70,
                template: function (row) {
                    let total = '-'
                    if (row.Format == "Stroke Play Net" || row.Format == "Stroke Play Gross" || (selectedCategory == grossCategoryValue)) {

                        if (row.Point > 0)
                            total = '+' + row.Point;
                        else if (row.Point == 0)
                            total = 'E';
                        else
                            total = row.Point;
                    }
                    else {
                        total = row.Point;

                    }
                    return total == null ? '-' : total;
                }
            },
            {
                title: `R ${round.Round}`,
                field: (round.Format == "Stroke Play Net") ? "NetScore" : "GrossScore",
                width: 50,
                template: function (row) {
                    let value = '-'

                    if (row.Thru != 0) value = (row.Format == "Stroke Play Net") ? (row.NetScore == null ? '-' : row.NetScore) : (row.GrossScore == null ? '-' : row.GrossScore);

                    if (row.Thru != 0 && selectedCategory == grossCategoryValue) {
                        if (value > 0)
                            value = '+' + value;
                        else if (value == 0)
                            value = 'E';
                        else
                            value = value;
                    }

                    return value;
                },
            },
            {
                field: 'IsVerified',
                title: 'Verified',
                template: function (row) {
                    return getStatus(row.IsVerified);
                    //return row.IsVerified == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : (row.IsVerified == "Y" )? '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>' : `<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> ${row.IsVerified} </span> `
                },
            },
            {
                field: '',
                title: '',
                width: 70,
                template: function (row) {
                    return `<span > <button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-sm btn-template-edit"> <i class="fa fa-address-card fs-17 "> </i> </button> </span>`;
                },
            },
        ];

        if (!(format?.TeeOffOption === "Split Staggered Start" || format?.TeeOffOption === "Staggered Start")) {
            columns = columns.filter(column => column.field !== 'Slot');
        }
        const dataTable = $('#tblLeaderboard').KTDatatable({
            data: {
                saveState: false, pageSize : 20,
                source : leaderBoardList,
            },
            columns: columns,
            layout: {
                scroll: true, footer: false, height: $(window).height() - 250,
            },
            sortable  : true, pagination: true,
            search    : { input: $('#generalSearch')},
            
        });

        dataTable.on('click', '.btn-template-edit', function (ss) {
            const index = $(ss.currentTarget).parents('tr').index();
               
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                fetchScorecard(dataTable.dataSet[index].DrawID)
                setSelectedPlayer(dataTable.dataSet[index]); 
            } 
        });

        setIsTable(true);
    }

    function getStatus(status) {
        var result = "";

        switch (status) {

            case "Y":
                result = '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>';
                break;

            case "WD":
                result = "Withdrawn";
                break;

            case "DQ":
                result = "Disqualified";
                break;

            default:
                result = '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>';

        }

        return result;
    }

    const fetchTournamentData = async (tournamentId) => {
        try {
            const res = await getTournamentData(tournamentId);
            let tCategory = res.tCategory
            let isGross = tCategory.find(c => c.IsGross == "Y");
            if (!!isGross) tCategory = [...tCategory, { ID: res.GrossCategoryValue, Name: "Gross", IsGross: "Y" }];
            setTournamentDetails(res.tournament)
            setSelectedRound(res.tRounds[0].ID)
            setSelectedCategory(tCategory[0].ID)
            setTournamentRound(res.tRounds)
            setTournamentCategory(tCategory)
            setGrossCategoryValue(res.GrossCategoryValue);
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    const fetchTournamentLeaderboard = async (round, categoryID) => {
        try {
            let res;
            if (categoryID == grossCategoryValue) {
                res = await getLeaderboardGross(param.id, round);
            } else {
                res = await getLeaderboard(param.id, round, categoryID);
            }

            if(isInit) {
                setIsInit(false)
            } else {               
               if(isTable) $('#tblLeaderboard').KTDatatable().destroy();
            }

            if(res.length > 0) {
                const leaderBoardData = res[0].List
                const round = tournamentRound.find(r => r.ID == selectedRound)
                // setLeaderBoardList(leaderBoardData.filter(i => i.RoundID == selectedRound));
                setLeaderBoardList(leaderBoardData.filter(i => moment(i.RoundDate).isSameOrBefore(round.Date)));
            }else{
                setLeaderBoardList([]);
            }
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    const fetchScorecard = async (drawID) => {
        try {
            swal.fire({ showConfirmButton: false, onOpen:() => swal.showLoading(), titleText: 'Loading', text: 'Please Wait...',  allowOutsideClick: false, allowEscapeKey: false,});        

            const response = await getTournamentScorecard(drawID, tournamentDetails.Hole);
            if(response?.TournamentPoint && response?.TournamentPoint.length > 0) setPointData(response.TournamentPoint);
            setGameDetails(response.Score);   
            if (response?.players) setPlayerList(response.players)
            setTournamentDraw(response.TournamentDraw)       
            swal.close();
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message, onOpen:() => swal.hideLoading() })
        }
    }

    const closeDrawer = () => {
        Config.Core.CloseDrawer();
        setTimeout(() => { 
            setSelectedPlayer(null);
            setGameDetails(null); 
            setScorecardStatus('')
        }, 200);
    }
    
    const handleBack = () => {
        history.push(`/tournament-leaderboard`);
    }

    const sendEmailLeaderboard = async () => {
        try {    
            const round = tournamentRound.find(r => r.ID == selectedRound)
            swal.fire({ showConfirmButton: false, onOpen:() => swal.showLoading(), titleText: 'Loading', text: 'Please Wait...',  allowOutsideClick: false, allowEscapeKey: false});
            await emailLeaderboard(tournamentDetails.ID, round.Round)
            swal.close();
            swal.fire({
                icon: 'success', timer: 1500, showConfirmButton: false,
                titleText: 'Email sent Successfully.',
            });            
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message, onOpen:() => swal.hideLoading() })
        }
    }

    return (<>
        <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0" id="kt_content" style={{paddingTop: '0px'}}>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile padding-lr20">
                    <div className="kt-portlet__head kt-portlet__head--lg  d-block padding-lr0 border-0">

                        <div id="roundInfoPanel" className="kt-notification margin-t10" style={{backgroundColor: "rgb(247, 248, 250)"}}>
                            <div className="kt-notification__item d-block">
                                <div className="row">
                                    <div className="col-3">
                                        <div className="kt-user-card-v2 mb-2">
                                            <div className="kt-user-card-v2__pic">
                                            <img id="txtTournamentImg" src={tournamentDetails?.Icon} alt="tournament-logo" />
                                            </div>
                                            <div className="kt-user-card-v2__details ">
                                                <span id="txtTournament" className="kt-user-card-v2__name text-clip"> {tournamentDetails?.Name} </span>
                                                <span className="kt-user-card-v2__desc ">
                                                    <span id="txtType"> {tournamentDetails?.Type} </span> | <span id="txtMembership">  {tournamentDetails?.MembershipType} </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2 d-flex-center">
                                        <div className="kt-user-card-v2 mb-2">
                                            <div className="kt-user-card-v2__details ">
                                                <span className="kt-user-card-v2__name ">Start </span>
                                                <span className="kt-user-card-v2__desc">
                                                    <span id="txtStartDate">{moment(tournamentDetails?.StartDate).format('DD-MMM-YYYY')}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2 d-flex-center">
                                        <div className="kt-user-card-v2 mb-2">
                                            <div className="kt-user-card-v2__details ">
                                                <span className="kt-user-card-v2__name">End </span>
                                                <span className="kt-user-card-v2__desc ">
                                                    <span id="txtEndDate">{moment(tournamentDetails?.EndDate).format('DD-MMM-YYYY')}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {!!tournamentRound && tournamentRound?.length > 0 && (
                                        <div className="col-3 d-flex-center">
                                            <div className="kt-user-card-v2 mb-2">
                                                <div className="kt-user-card-v2__details ">
                                                    <span className="kt-user-card-v2__name ">Tee Of Option </span>
                                                    <span className="kt-user-card-v2__desc ">
                                                        <span id="txtTeeOption">{format?.TeeOffOption}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="col-2 d-flex-center">
                                        <div className="kt-user-card-v2 mb-2">
                                            <div className="kt-user-card-v2__details ">
                                                <span className="kt-user-card-v2__name ">Format </span>
                                                <span className="kt-user-card-v2__desc ">
                                                    {grossCategoryValue == selectedCategory ? (
                                                        <span id="txtFormat">Stroke Play Gross</span>
                                                    ) : (
                                                        <span id="txtFormat">{format?.Format}</span>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="ControlHolder" className="kt-form kt-form--label-right  mt-3" role="form">
                            <div className="form-group row margin-b10">                           
                                <div className="col-lg-3">
                                    <div className="kt-input-icon kt-input-icon--left ">
                                        <input type="text" className="form-control playerSearch" placeholder="Search Player..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>

                                <label id="pnlRoundLabel" className="col-form-label col-lg-2 pnlRoundLabel"> Round </label>
                                <div className="col-lg-2 pnlRound">
                                    <select 
                                        id="ddlRound" 
                                        title="Select Round"
                                        value={selectedRound || ''}
                                        onChange={(event) => setSelectedRound(event.target.value)} 
                                        name="ddlRound" 
                                        className="form-control kt-selectpicker show-tick"
                                    >
                                        {(!!tournamentRound && tournamentRound.length > 0) && tournamentRound.map((r, i) => (
                                            <option value={r.ID} key={r.ID}>Round {r.Round} ({moment(r.Date).format('DD MMM YYYY')})</option>
                                        ))}
                                    </select>
                                </div>

                                <label className="col-form-label col-lg-2 categorypnl"> Category </label>
                                <div className="col-lg-3 categorypnl">
                                    <select 
                                        id="ddlCategory" 
                                        title="Select Category" 
                                        name="ddlCategory" 
                                        value={selectedCategory || ''}
                                        onChange={(event) => setSelectedCategory(event.target.value)} 
                                        className="form-control kt-selectpicker show-tick"
                                    >
                                        {(!!tournamentCategory && tournamentCategory.length > 0) && tournamentCategory.map((r, i) => (
                                            <option value={r.ID} key={r.ID}>{r.Name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit padding-t10 mb-3">
                        <div id="pnlLoader" className="text-center margin-tb20 hidden">
                            <span className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--info"></span>
                        </div>
                        <div id="tblLeaderboard" className="kt-datatable table-striped clickable custom-table-border"></div>
                        {selectedPlayer && (
                            <RightSideDetails title="Tournament Scorecard" onCloseDrawer={() => closeDrawer()}>
                                {gameDetails && selectedPlayer.Format == 'Gross Stableford' && (
                                    <StableFordGross
                                        tournamentDetails={tournamentDetails}
                                        tournamentRound={tournamentRound}
                                        format={format}
                                        gameDetails={gameDetails}
                                        selectedPlayer={selectedPlayer}
                                        setScorecardStatus={setScorecardStatus}
                                        scorecardStatus={scorecardStatus}
                                        closeDrawer={closeDrawer}
                                        fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                        pointData={pointData}
                                        setShowPointModal={setShowPointModal}
                                    />
                                )}
                                {gameDetails && selectedPlayer.Format == 'Modified Stableford' && (
                                    <ModifiedStableford
                                        tournamentDetails={tournamentDetails}
                                        tournamentRound={tournamentRound}
                                        format={format}
                                        gameDetails={gameDetails}
                                        selectedPlayer={selectedPlayer}
                                        setScorecardStatus={setScorecardStatus}
                                        scorecardStatus={scorecardStatus}
                                        closeDrawer={closeDrawer}
                                        fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                        pointData={pointData}
                                        setShowPointModal={setShowPointModal}
                                    />
                                )}
                                {gameDetails && selectedPlayer.Format == 'Bogey' && (
                                    <Bogey
                                        tournamentDetails={tournamentDetails}
                                        tournamentRound={tournamentRound}
                                        format={format}
                                        gameDetails={gameDetails}
                                        selectedPlayer={selectedPlayer}
                                        setScorecardStatus={setScorecardStatus}
                                        scorecardStatus={scorecardStatus}
                                        closeDrawer={closeDrawer}
                                        fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                        pointData={pointData}
                                        setShowPointModal={setShowPointModal}
                                    />
                                )}
                                {gameDetails && selectedPlayer.Format == 'Net Stableford' && (
                                    <StableFordNet
                                        tournamentDetails={tournamentDetails}
                                        tournamentDraw={tournamentDraw}
                                        tournamentRound={tournamentRound}
                                        format={format}
                                        gameDetails={gameDetails}
                                        selectedPlayer={selectedPlayer}
                                        setScorecardStatus={setScorecardStatus}
                                        scorecardStatus={scorecardStatus}
                                        closeDrawer={closeDrawer}
                                        fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                        pointData={pointData}
                                        setShowPointModal={setShowPointModal}
                                    />
                                )}
                                {gameDetails && selectedPlayer.Format == 'Stroke Play Gross' && (
                                    <StrokePlayGross
                                        playerList={playerList}
                                        tournamentDetails={tournamentDetails}
                                        tournamentRound={tournamentRound}
                                        format={format}
                                        gameDetails={gameDetails}
                                        selectedPlayer={selectedPlayer}
                                        setScorecardStatus={setScorecardStatus}
                                        scorecardStatus={scorecardStatus}
                                        closeDrawer={closeDrawer}
                                        fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                    />
                                )}
                                {gameDetails && selectedPlayer.Format == 'Stroke Play Net' && (
                                    <StrokePlayNet
                                        playerList={playerList}
                                        tournamentDetails={tournamentDetails}
                                        tournamentRound={tournamentRound}
                                        format={format}
                                        gameDetails={gameDetails}
                                        selectedPlayer={selectedPlayer}
                                        setScorecardStatus={setScorecardStatus}
                                        scorecardStatus={scorecardStatus}
                                        closeDrawer={closeDrawer}
                                        fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                    />
                                )}
                                {gameDetails && selectedPlayer.Format == 'Fourball Betterball' && (
                                    <FourBall
                                        playerList={playerList}
                                        tournamentDetails={tournamentDetails}
                                        tournamentDraw={tournamentDraw}
                                        tournamentRound={tournamentRound}
                                        gameDetails={gameDetails}
                                        currentRound={format}
                                        format={format}
                                        selectedPlayer={selectedPlayer}
                                        setScorecardStatus={setScorecardStatus}
                                        scorecardStatus={scorecardStatus}                                                                                                                   
                                        pointData = {pointData}
                                        setShowPointModal={setShowPointModal}
                                        closeDrawer={closeDrawer}
                                        fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                    />
                                )}
                                {gameDetails && selectedPlayer.Format == 'Betterball Bogey' && (
                                    <BetterballBogey
                                        playerList={playerList}
                                        tournamentDetails={tournamentDetails}
                                        tournamentDraw={tournamentDraw}
                                        tournamentRound={tournamentRound}
                                        gameDetails={gameDetails}
                                        currentRound={format}
                                        format={format}
                                        selectedPlayer={selectedPlayer}
                                        setScorecardStatus={setScorecardStatus}
                                        scorecardStatus={scorecardStatus}                                                                                                                   
                                        pointData = {pointData}
                                        setShowPointModal={setShowPointModal}
                                        closeDrawer={closeDrawer}
                                        fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                    />
                                )}
                                {gameDetails && selectedPlayer.Format == 'Cumulative Stableford' && (
                                    <CumulativeFourBall
                                        playerList={playerList}
                                        tournamentDetails={tournamentDetails}
                                        tournamentDraw={tournamentDraw}
                                        tournamentRound={tournamentRound}
                                        gameDetails={gameDetails}
                                        currentRound={format}
                                        format={format}
                                        selectedPlayer={selectedPlayer}
                                        setScorecardStatus={setScorecardStatus}
                                        scorecardStatus={scorecardStatus}                                                                                                                   
                                        pointData = {pointData}
                                        setShowPointModal={setShowPointModal}
                                        closeDrawer={closeDrawer}
                                        fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                    />
                                )}
                                {gameDetails && selectedPlayer.Format == "Foursomes" && (
                                    <Foursomes
                                        playerList={playerList}
                                        tournamentDetails={tournamentDetails}
                                        tournamentDraw={tournamentDraw}
                                        tournamentRound={tournamentRound}
                                        gameDetails={gameDetails}
                                        currentRound={format}
                                        format={format}
                                        selectedPlayer={selectedPlayer}
                                        setScorecardStatus={setScorecardStatus}
                                        scorecardStatus={scorecardStatus}                                                                                                                   
                                        pointData = {pointData}
                                        setShowPointModal={setShowPointModal}
                                        closeDrawer={closeDrawer}
                                        fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                    />
                                )}
                                {gameDetails && selectedPlayer.Format == "Greensome" && (
                                    <Greensomes
                                        playerList={playerList}
                                        tournamentDetails={tournamentDetails}
                                        tournamentDraw={tournamentDraw}
                                        tournamentRound={tournamentRound}
                                        gameDetails={gameDetails}
                                        currentRound={format}
                                        format={format}
                                        selectedPlayer={selectedPlayer}
                                        setScorecardStatus={setScorecardStatus}
                                        scorecardStatus={scorecardStatus}                                                                                                                   
                                        pointData = {pointData}
                                        setShowPointModal={setShowPointModal}
                                        closeDrawer={closeDrawer}
                                        fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                    />
                                )}
                                {gameDetails && selectedPlayer.Format == "Scramble" && (
                                    <Scramble
                                        playerList={playerList}
                                        tournamentDetails={tournamentDetails}
                                        tournamentDraw={tournamentDraw}
                                        tournamentRound={tournamentRound}
                                        gameDetails={gameDetails}
                                        currentRound={format}
                                        format={format}
                                        selectedPlayer={selectedPlayer}
                                        setScorecardStatus={setScorecardStatus}
                                        scorecardStatus={scorecardStatus}                                                                                                                   
                                        pointData = {pointData}
                                        setShowPointModal={setShowPointModal}
                                        closeDrawer={closeDrawer}
                                        fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                    />
                                )}
                                {gameDetails && selectedPlayer.Format == "Scramble - Stroke Play Net" && (
                                    <ScrambleStrokePlayNet
                                        playerList={playerList}
                                        tournamentDetails={tournamentDetails}
                                        tournamentDraw={tournamentDraw}
                                        tournamentRound={tournamentRound}
                                        gameDetails={gameDetails}
                                        currentRound={format}
                                        format={format}
                                        selectedPlayer={selectedPlayer}
                                        setScorecardStatus={setScorecardStatus}
                                        scorecardStatus={scorecardStatus}                                                                                                                   
                                        pointData = {pointData}
                                        setShowPointModal={setShowPointModal}
                                        closeDrawer={closeDrawer}
                                        fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                    />
                                )}
                            </RightSideDetails> 
                        )} 
                    </div>
                    <div className="kt-portlet__foot">
                        <div className="kt-form__actions">
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <button id="btnBack" type="button" className="btn btn-secondary margin-l10 d-inline-block pull-right" onClick={handleBack}> Back </button>
                                    <button id="btnEmail" type="button" className="btn btn-brand d-inline-block pull-right" onClick={sendEmailLeaderboard}>Email Tournament Leader Board</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {showPointModal && (
            <PointModal
                show={showPointModal}
                onDismissModal={()=> setShowPointModal(false)}
                pointData={pointData}
            />
        )}
    </>)
}

export default LeaderBoardPlayer